import React, { memo } from "react";
import {
  Header,
  Inner,
  Logo,
  BtnOpenMenu,
  BtnOpenMenuIcon,
  Drawer,
  MenuList,
  MenuListItem,
  SelectLangMenu,
  MenuContainerProfile,
  ProfileName,
  LinkHome
} from "./Styled";

import useHeaderPage from "./useHeaderPage";
import LogoImg from "./images/logo.png";
import {useAuth} from "../../contexts/Auth.contexts";

const HeaderPage = () => {
  const {profile} = useAuth();
  const {
    transitionIn,
    openMenu,
    pathname,
    pagePathname,
    toggleDrawer,
    hadleGoLink,
    translate,
  } = useHeaderPage();

  return (
    <>
      <Header
        transition={transitionIn}
        pageTermOfUse={pathname === pagePathname ? true : false}
      >
        <Inner>
          {pathname !== pagePathname && (
            <LinkHome title="Home" onClick={() => hadleGoLink("/")}>
              <Logo src={LogoImg} transition={transitionIn} />
            </LinkHome>
          )}
          <BtnOpenMenu
            color="default"
            type="button"
            className="btn-open-menu"
            onClick={() => toggleDrawer(true)}
          >
            <BtnOpenMenuIcon transition={transitionIn} />
          </BtnOpenMenu>
        </Inner>
      </Header>
      <Drawer
        anchor="right"
        open={openMenu}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <MenuList>
          <MenuListItem>
            <SelectLangMenu />
          </MenuListItem>
          <MenuListItem>
            <MenuContainerProfile>
              <ProfileName>{profile?.pess_nome}</ProfileName>
            </MenuContainerProfile>
            </MenuListItem>
          <MenuListItem onClick={() => hadleGoLink("/")} button>
            {translate("headerPage.menu.home")}
          </MenuListItem>
          <MenuListItem onClick={() => hadleGoLink("/termos-de-uso")} button>
            {translate("headerPage.menu.termOfUse")}
          </MenuListItem>
          <MenuListItem onClick={() => hadleGoLink("/documentos")} button>
            {translate("headerPage.menu.documents")}
          </MenuListItem>
          <MenuListItem onClick={() => hadleGoLink("/holerites")} button>
            {translate("headerPage.menu.holerites")}
          </MenuListItem>
          <MenuListItem onClick={() => hadleGoLink("/informe-rendimentos")} button>
            {translate("headerPage.menu.informe-rendimentos")}
          </MenuListItem>
          <MenuListItem onClick={() => hadleGoLink("/logout")} button>
            {translate("headerPage.menu.logout")}
          </MenuListItem>
        </MenuList>
      </Drawer>
    </>
  );
};

export default memo(HeaderPage);
