import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Utils } from "utils";

import Api from "service/api";

const MIDIA_URL = process.env.REACT_APP_MIDIA_URL;
const setPrefixMidia = (midia) => `${MIDIA_URL}/${midia}`;

const useMessages = () => {
  const history = useHistory();
  const params = useParams();
  const [comunicacaoId] = useState(params.id);
  const [loadingPage, setLoadingPage] = useState(true);
  const [transitionIn, setTransitionIn] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [comunicacao, setComunicacao] = useState([]);
  const [previewFotos, setPreviewFotos] = useState({});
  const [filesFotos, setFileFotos] = useState("");
  const [comentario, setComentario] = useState("");
  const [fieldErrors, setFieldErrors] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  const containerRef = useRef(null);
  const { t: translate } = useTranslation();

  const currentLang = window.localStorage.i18nextLng;
  document.title = translate("messages.head.title");

  useEffect(() => {
    //window.scrollTo(0, 0);
    const loadData = async () => {
      await getData();
    };
    loadData();
  }, []);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const sendViewNotify = async (comunicacaoId) => {
    await Api.post(`notificacao/visualizacao-comunicacao`, {
      comunicacao_id:comunicacaoId,
    }).catch((err) => {
      console.log(err);
    });
  };

  const getData = async () => {
    try {
      await Api.get(`/comunicacao-altum/${params.id}`)
        .then((response) => {
          setComunicacao(response.data.listagem);
          setLoadingPage(false);
          setTimeout(() => {
            setTransitionIn(true);
            scrollToBottom();
          }, 100);

          sendViewNotify(params.id);
        })
        .catch((error) => {
          setLoadingPage(true);
          setTimeout(() => {
            setTransitionIn(true);
          }, 100);
          console.log(error);
        });
    } catch (error) {
      if (Api.isCancel(error)) {
        setTransitionIn(true);
      } else {
        setTransitionIn(true);
        throw error;
      }
    }
  };

  const hadleGoback = () => {
    history.push(Utils.setPrefix(`/comunicacao-interna`));
  };

  const handleChangeComentario = (event) => {
    setComentario(event.target.value);
    setFieldErrors(fieldErrors.filter((inp) => inp.name !== "comentario"));
  };

  const handleChangeFotos = (event) => {
    let input = event.currentTarget;
    let validImageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];

    let img = URL.createObjectURL(input.files[0]);
    let fileName = input.files[0].name;
    if (validImageTypes.indexOf(input.files[0]["type"]) !== -1) {
      setPreviewFotos({ img, fileName });
      setFileFotos(input.files);
      setFieldErrors(fieldErrors.filter((inp) => inp.name !== "fotos"));
    }
  };

  const handleRemoveFoto = () => {
    setPreviewFotos({});
    document.querySelector("button [type=file]").value = null;
  };

  const validateForm = () => {
    let errors = 0;
    let filelds = [];
    document.getElementById("msg-anexo").innerText = "";

    if (comentario === "" && !previewFotos.fileName) {
      filelds.push({ name: "comentario", error: true });
      errors++;
    }

    setFieldErrors(filelds);
    return { errors, filelds };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { errors } = validateForm();

    if (errors > 0) {
      setMessageAlert(translate("addMessage.formValidade"));
      setOpenAlert(true);
      return false;
    }

    setLoadingForm(true);
    let anexo = filesFotos[0];
    const comment = comentario === "" ? "Anexo" : comentario;

    await Api.post(`comunicacao-altum/answer/${params.id}`, {
      comentario: comment,
      anexo,
    })
      .then((response) => {
        setComentario("");
        setFileFotos("");
        setPreviewFotos({});
        document.querySelector("button [type=file]").value = null;
        setLoadingForm(false);
        (async () => await getData())();
      })
      .catch((error) => {
        console.log(error);
        setLoadingForm(false);
      });
  };

  const handleGetFileName = (fileUrl) => {
    const frag = fileUrl.split("?");
    const pathFrag = frag[0].split("/");
    return pathFrag[5];
  };

  return {
    transitionIn,
    loadingPage,
    comunicacao,
    currentLang,
    hadleGoback,
    setPrefixMidia,
    translate,
    Utils,
    handleSubmit,
    setLoadingPage,
    handleChangeFotos,
    handleRemoveFoto,
    handleChangeComentario,
    comunicacaoId,
    comentario,
    setComentario,
    loadingForm,
    fieldErrors,
    previewFotos,
    openAlert,
    messageAlert,
    setOpenAlert,
    handleGetFileName,
    containerRef,
  };
};

export default useMessages;
