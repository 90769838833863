import React from "react";
//import { Container, Grid } from "@material-ui/core";
import moment from "moment";
import Loading from "components/loading";
import ButtonCustom from "components/buttonCustom";
import AlertCustom from "components/alertCustom";
import useMessages from "./useMessages";

import {
  Main,
  Container,
  Title,
  TitleIcon,
  HeaderMessage,
  Status,
  Text,
  Data,
  BtnGroup,
  Icon,
  GroupMessages,
  ListMessage,
  ButtomDowload,
  ButtomLinkDownload,
  NewMessageContainer,
  NewMessageField,
  NewMessageBtnAttachment,
  NewMessageBtnSend,
  GroupField,
  ListPreviewFotos,
  PreviewFoto,
  FormPage,
  MessageAnexo,
} from "./Styled";

import ComunImg from "./images/talk.png";

const Message = () => {
  const {
    transitionIn,
    loadingPage,
    comunicacao,
    currentLang,
    hadleGoback,
    translate,
    Utils,
    messageAlert,
    openAlert,
    setOpenAlert,
    handleGetFileName,
    handleSubmit,
    loadingForm,
    comentario,
    handleChangeComentario,
    handleChangeFotos,
    handleRemoveFoto,
    previewFotos,
    fieldErrors,
  } = useMessages();

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Main>
        <Container>
          <Title transition={transitionIn}>
            <TitleIcon src={ComunImg} />
            {translate("messages.title")}
          </Title>

          <BtnGroup transition={transitionIn}>
            <ButtonCustom
              color="default"
              type="button"
              size="large"
              className="btn-goback"
              startIcon={<Icon className="icon icon-arrow" color="black" />}
              onClick={hadleGoback}
              fullWidth
            >
              {translate("messages.btnGoBack")}
            </ButtonCustom>
          </BtnGroup>

          {comunicacao.length === 0 ? (
            <Text center noContent>
              {translate("messages.noContent")}
            </Text>
          ) : (
            <>
              <HeaderMessage transition={transitionIn}>
                <Status type={comunicacao.cmit_status}>
                  {translate(
                    `messages.status.${comunicacao.cmit_status.toLowerCase()}`
                  )}
                </Status>
                <Text bold="bold">
                  {comunicacao.cmit_id_comunicacao_interna}{" "}
                  {comunicacao.cmit_area}
                </Text>
                <Text>
                  {comunicacao.cmit_tipo} -{" "}
                  {moment(comunicacao.cmit_mes_referencia).format(
                    Utils.formatDataIsLang(currentLang)
                  )}
                </Text>
              </HeaderMessage>

              <GroupMessages>
                <ListMessage transition={transitionIn}>
                  <Text>{comunicacao.cmit_comentario}</Text>
                  {comunicacao.arquivo.length > 0 &&
                    comunicacao.arquivo.map((foto, index) => (
                      <ButtomDowload key={index} transition={transitionIn}>
                        <ButtomLinkDownload
                          key={index}
                          href={foto.cmia_arquivo}
                          className="btn-green"
                          download={handleGetFileName(foto.cmia_arquivo)}
                          target="_blank"
                          title={handleGetFileName(foto.cmia_arquivo)}
                        >
                          <Icon className="icon icon-download" /> Ver Anexo
                        </ButtomLinkDownload>
                      </ButtomDowload>
                    ))}
                  <Data>
                    {moment(comunicacao.cmit_dh_registro).format(
                      Utils.formatDataHoraIsLang(currentLang)
                    )}
                  </Data>
                </ListMessage>

                {comunicacao.resposta &&
                  comunicacao.resposta.map((resp) => (
                    <ListMessage
                      key={resp.cmir_id_comunicacao_resposta}
                      transition={transitionIn}
                      me={!resp.cmir_resposta_solicitante}
                    >
                      <Text>{resp.cmir_resposta}</Text>

                      {resp.cmir_arquivo && (
                        <ButtomDowload transition={transitionIn}>
                          <ButtomLinkDownload
                            href={resp.cmir_arquivo}
                            className="btn-green"
                            download={handleGetFileName(resp.cmir_arquivo)}
                            target="_blank"
                            title={handleGetFileName(resp.cmir_arquivo)}
                          >
                            <Icon className="icon icon-download" /> Ver Anexo
                          </ButtomLinkDownload>
                        </ButtomDowload>
                      )}
                      <Data>
                        {resp.cmir_resposta_solicitante
                          ? "Você"
                          : "Grupo Altum"}{" "}
                        {" em: "}
                        {moment(resp.cmir_dh_registro).format(
                          Utils.formatDataHoraIsLang(currentLang)
                        )}
                      </Data>
                    </ListMessage>
                  ))}
              </GroupMessages>
            </>
          )}

          {["PENDENTE", "EM_ANALISE"].includes(comunicacao.cmit_status) ? (
            <GroupField>
              <MessageAnexo id="msg-anexo" />
              <FormPage
                encType="multipart/form-data"
                method="post"
                autoComplete="off"
                loadingForm={loadingForm}
                onSubmit={handleSubmit}
              >
                <NewMessageContainer>
                  <NewMessageField
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={10}
                    id="comentario"
                    name="comentario"
                    label={translate("addMessage.textareaComentario")}
                    autoComplete="off"
                    value={comentario}
                    onChange={handleChangeComentario}
                    error={
                      fieldErrors.find(
                        (inp) => inp.name === "comentario" && inp.error === true
                      )
                        ? true
                        : false
                    }
                  />
                  <NewMessageBtnAttachment
                    disabled={previewFotos.length > 0}
                    name="anexo"
                    label={<Icon className="icon icon-attached" />}
                    className="btn-anexar"
                    labelSelect={translate("addMessage.titleSelectFoto")}
                    onChange={handleChangeFotos}
                    error={
                      fieldErrors.find(
                        (inp) => inp.name === "anexo" && inp.error === true
                      )
                        ? true
                        : false
                    }
                  />
                  <NewMessageBtnSend
                    type="submit"
                    color="default"
                    className="btn-green"
                  >
                    <Icon className="icon icon-send" />
                  </NewMessageBtnSend>
                </NewMessageContainer>
                {previewFotos.fileName && (
                  <ListPreviewFotos>
                    <PreviewFoto
                      src={previewFotos.img}
                      fileName={previewFotos.fileName}
                      onClick={() => handleRemoveFoto()}
                    />
                  </ListPreviewFotos>
                )}
              </FormPage>
            </GroupField>
          ) : (
            <></>
          )}
        </Container>
      </Main>

      <AlertCustom
        message={messageAlert}
        openMessage={openAlert}
        closeMessage={() => setOpenAlert(false)}
        transition="down"
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </React.Fragment>
  );
};

export default Message;
