import styled from "styled-components";
import Theme from "Theme";

const mdScreen = "1260px";
const initialSizeSmallScreen = "651px";

const Main = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: ${Theme.colorBodyDefault};
  padding-top: 20px;
  position: absolute;
  top:140px;
`;

const WelcomeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;
`;

const WelcomeText = styled.h1`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 5px;
  @media screen and (max-width: ${initialSizeSmallScreen}) {
    font-size: 18px;
    margin-bottom: 5px;
  }
`;

const TextInstruction = styled.p`
  font-size: 16px;
  font-weight: bold;
  @media screen and (max-width: ${initialSizeSmallScreen}) {
    font-size: 14px;
  }
`;

const MenuBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${mdScreen}) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media screen and (max-width: ${initialSizeSmallScreen}) {
  }
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 200px;
  border-radius: 10px;

  font-size: 18px;
  margin: 8px;
  padding: 10px;
  background: #e6e6e6;
  &&:hover {
    background-color: #d9d9d9;
    cursor: pointer;
  }

  @media screen and (max-width: ${mdScreen}) {
    height: 150px;
    width: 26%;
    font-size: 16px;
  }

  @media screen and (max-width: ${initialSizeSmallScreen}) {
    height: 150px;
    width: 42%;
    font-size: 16px;
  }
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  width: 50px;
  height: 50px;
`;

const IconLabel = styled.span`
  margin-top: 8px;
  text-align: center;
`;

const ImageIcon = styled.img`
  max-width: 50px;
  height: auto;
  margin: auto auto 15px;
  display: table;
`;

const ContainerNotificacao = styled.div`
  position: absolute;
  display: flex;
  min-width: 280px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  bottom: 8px;
  left: 2px;
  & > h2 {
    margin-bottom: 10px;
    background-color: ${Theme.colorLightBgCommunication};
    width: 100%;
    text-align: center;
    padding: 5px;
  }
  @media screen and (max-width: ${initialSizeSmallScreen}) {
    margin-top: 20px;
  }
`;

const NotificacaoList = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px;
  text-decoration: none;
  color: #000;
  border: 1px solid grey;
  &&:hover {
    background-color: #e6e6e6;
  }
`;

const DialogBox = styled.div`
  display: ${(props) => props.display ?? "none"};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
`;

const DialogButton = styled.button`
  background-color: ${Theme.colorPrimary};
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
`;

const DialogButtonCancel = styled(DialogButton)`
  background-color: #ccc;
`;

export {
  Main,
  MenuBox,
  MenuItem,
  Icon,
  IconImage,
  IconLabel,
  ImageIcon,
  WelcomeBox,
  WelcomeText,
  TextInstruction,
  ContainerNotificacao,
  NotificacaoList,
  DialogBox,
  DialogButton,
  DialogButtonCancel,
};
