import { useState, useEffect } from "react";
import Store from "store";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/Auth.contexts";
import { Utils } from "utils";
import Api from "service/api";

const useLogin = () => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [transitionIn, setTransitionIn] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [renderPage, setRenderPage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [message, setMessage] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const history = useHistory();
  const {
    setUpdatePassword,
    setContractAccepted,
    SignIn,
    AddProfile,
    setIsPendingEmailConfirmation
  } = useAuth();
  const { t: translate } = useTranslation();

  document.title = translate("login.head.title");

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const params = new URLSearchParams(window.location.search);
      const paramRecovery = params.get("forgot-password");
      const view = paramRecovery === "true" ? "esqueci-minha-senha" : "login";
      setRenderPage(view);
      setLoadingPage(false);
    }, 600);

    setTimeout(() => {
      setTransitionIn(true);
    }, 800);
  }, []);

  const goLink = (link) => history.push(Utils.setPrefix(link));

  const onButtonDisabled = (login, password) => {
    setButtonDisabled(false);
    if (!Utils.validateCPF(login) || password.length < 6) {
      setButtonDisabled(true);
    }
  };

  const onChangeLogin = (event) => {
    setLogin(event.target.value);
    setLoginError(false);
    onButtonDisabled(event.target.value, password);
    if (!Utils.validateCPF(event.target.value)) {
      setLoginError(true);
    }
  };

  const onChangePassword = (event) => {
    setPassword(event.target.value);
    setPasswordError(false);
    onButtonDisabled(login, event.target.value);
    if (event.target.value.length < 6) {
      setPasswordError(true);
    }
  };

  const onSubmitLogin = (event) => {
    event.preventDefault();

    setLoadingForm(true);
    setMessageSuccess("");

    Api.post("login_altum", {
      login: login.replace(/[^\d]+/g, ""),
      password,
    })
      .then((response) => {
        Api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
        const { token } = response.data;
        Store.set("token_altum", token);
        setMessage("");
        const randomPass = Math.random().toString(36).substring(7);
        const pass = `${randomPass}-${password}`;
        localStorage.setItem("ead_pass", pass);
        Api.get("/usuario").then((response) => {
          const { termo_uso, usuario, confirmacao_email_pendente } =
            response.data;
          AddProfile(usuario);
          SignIn();
          setContractAccepted(termo_uso);
          setIsPendingEmailConfirmation(confirmacao_email_pendente);
          setLoadingForm(false);

          if (usuario.pess_solicita_senha === "S") {
            setUpdatePassword(true);
            history.push(Utils.setPrefix("/atualizar-senha"));
            return;
          }

          if (confirmacao_email_pendente) {
            history.push(Utils.setPrefix("/pendding-email-confirmation"));
          }

          if (termo_uso) {
            history.push(Utils.setPrefix("/"));
            return;
          }

          history.push(Utils.setPrefix("/termos-de-uso"));
        });
      })
      .catch((error) => {
        console.log(error);
        setLoadingForm(false);
        setOpenMessage(true);
        setMessage(translate("login.form.msg.loginInvalid"));
      });
  };

  const onSubmitPasword = (event) => {
    event.preventDefault();

    setLoadingForm(true);

    const cpf = login.replace(/[^\d]+/g, "");
    const cpfPass = cpf.substring(0, 6);

    Api.post("grupo-altum/send-recovery-link", {
      cpf,
    })
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          if (data.success) {
            setLoadingForm(false);
            setRenderPage("login");
            setMessageSuccess(
              data.message
            )
          }else{
            //history.push(Utils.setPrefix(`/password-recovery?cpf=${cpf}`));
            setLoadingForm(false);
            setRenderPage("login");
            setMessageSuccess(
              `${translate("login.form.msg.changePasswordSuccess")}${cpfPass}`
            );
          }
        }
      })
      .catch((error) => {
        //console.log(error.response.data.message);
        setLoadingForm(false);
        setOpenMessage(true);
        //setMessage(translate("login.form.msg.loginInvalid"));
        setMessage(error.response.data.message || "Erro ao enviar o link de recuperação de senha");
      });
  };

  return {
    transitionIn,
    loadingPage,
    loadingForm,
    renderPage,
    openMessage,
    login,
    password,
    loginError,
    passwordError,
    buttonDisabled,
    message,
    messageSuccess,
    setRenderPage,
    setOpenMessage,
    onChangeLogin,
    onChangePassword,
    onSubmitLogin,
    onSubmitPasword,
    goLink,
    translate,
    Utils,
  };
};

export default useLogin;
